<template>
  <div>
    <!-- 学员信息 -->
    <stu-info />
    <div class="margin-top book-info bg-white">
      <div class=" padding flex aligin-items-center ">
        <border-title titleName="教材杂费信息" />
        <div class="margin-left">
          <el-button type="primary" size="mini" @click="selectTeachBooks">选择教材杂费</el-button>
        </div>
      </div>
    </div>
    
    
    <!-- 订单标签 -->
    <order-tag />
    <!-- 经办信息 -->
    <handle-info />
    
    <div class="sign-up-bottom padding">
      <div class="sign-up-bottom-btn">
        <el-button type="primary" size="medium">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import StuInfo from '../components/stuInfo.vue';
  import HandleInfo from '../components/handleInfo.vue';
  import OrderTag from '../components/orderTag.vue';
  export default {
    components: {
      StuInfo,
      OrderTag,
      HandleInfo,
    },
    data() {
      return {
        key: ''
      }
    },
    created() {
      
    },
    mounted() {
      
    },
    methods: {
      selectTeachBooks() {
        this.$Dialog.AddTextbook()
      }
    },
  }
</script>

<style scoped>
  .book-info {
    min-height: 300px;
  }
  .sign-up-bottom {
    bottom: 0;
    right: 0;
    position: fixed;
  }
</style>
